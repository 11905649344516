/**
 * Copied from https://www.youngdeveloper.co/posts/react-markdown-code-and-syntax-highlighting
 */

import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const CodeBlock = ({ node }) => {
  const { children } = node;
  if(children.length === 1 && children[0].tagName === 'code' && children[0].children.length === 1 && children[0].children[0].type === 'text') {
    return (
      <SyntaxHighlighter language="javascript" style={atomDark}>
        {children[0].children[0].value.trim()}
      </SyntaxHighlighter>
    );
  }
  throw new Error('<pre> called without code');
};

export default function Markdown({ source }) {
  return <ReactMarkdown components={{ pre:CodeBlock }} rehypePlugins={[rehypeRaw]}>{source}</ReactMarkdown>;
}

const seen = [];
function replacer(key, val) {
   if (val != null && typeof val == "object") {
        if (seen.indexOf(val) >= 0) {
            return;
        }
        seen.push(val);
    }
    return val;
}
